import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
// eslint-disable-next-line
// import { Margin, mx,my,mt,mr,mb,ml, Padding, px,py,pt,pr,pb,pl } from 'styled-components-spacing';

export const HeadingStyle = `
  font-family: var(--font-family);
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
`;

export const Heading = styled.h1`
  ${HeadingStyle}

  ${breakpoint("lg")`
    font-size: 46px;
  `}
`;

export const Heading2 = styled.h2`
  ${HeadingStyle}

  ${breakpoint("lg")`
    font-size: 36px;
  `}

  ${props => props.fgColor && `
    color: var(--color-${props.fgColor});
  `}
`;

export const Heading3 = styled.h3`
  ${HeadingStyle}

  font-size: 20px;

  ${breakpoint("lg")`
    font-size: 25px;
  `}
`;

export const Heading4 = styled.h4`
  font-size: inherit;
  font-weight: normal;
  color: inherit;
  margin: 0;
`;

export const HeadingResetStyle = `
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  ${breakpoint("md")`
    font-size: inherit;
  `}

  ${breakpoint("lg")`
    font-size: inherit;
  `}
`;

export const PageHeading = styled.h1`
  ${HeadingStyle}
  padding: 50px 0;
  ${props => props.bgColor && `
    color: var(--color-${props.fgColor});
    background-color: var(--color-${props.bgColor});
    color: var(--color-white);
  `}

  ${breakpoint("lg")`
    font-size: 18px;
  `}
`;

// export default Heading;
